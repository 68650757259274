<script setup>
import { onMounted, reactive, ref } from "vue";
import { useCartStore } from "@/store";
import { Offcanvas } from "bootstrap";
import { getAllProduct, getAllType } from "@/global";
import Product from "@/components/Product";
onMounted(() => {
  console.log("我來了");
});
const productList = reactive(getAllProduct());
const beans = ref(getAllType());
const storeCart = useCartStore();
const currentProduct = ref();
// init default use colombia first item
currentProduct.value = productList.colombia[0];

function spyScroll(scrollToId, collapseId) {
  const yOffset = -100;
  const element = document.getElementById(scrollToId);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  document.getElementById(collapseId).classList.add("show");
  window.scrollTo({ top: y, behavior: "smooth" });
  // document.getElementById(scrollToId).scrollIntoView({ block: "start", behavior: "smooth" });
}

function closeOffcanvas(event) {
  event.stopPropagation();
  var myOffcanvas = document.getElementById("cart-offcanvas");
  var bsOffcanvas = new Offcanvas(myOffcanvas);
  bsOffcanvas.hide();
  document.querySelector("#cart-offcanvas").classList.remove("show");
  document.querySelector("#cart-offcanvas").classList.add("offcanvas-start");
  document.querySelectorAll(".offcanvas-backdrop").forEach((e) => e.remove());
}

function pictureFullModal(obj) {
  currentProduct.value = { ...obj };
}
</script>

<template>
  <section class="wavepo-banner">
    <header id="wavepo-carousel" class="carousel slide" data-bs-ride="carousel">
      <div v-show="false" class="carousel-indicators">
        <button type="button" data-bs-target="#wavepo-carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
          <img src="~@/assets/banner-1.jpg" class="d-block w-100" alt="" />
        </div>
      </div>
      <button v-show="false" class="carousel-control-prev" type="button" data-bs-target="#wavepo-carousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button v-show="false" class="carousel-control-next" type="button" data-bs-target="#wavepo-carousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </header>
  </section>
  <section class="slogan py-3 text-start" style="background: rgb(0 0 0 / 13%)">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <div class="d-flex justify-content-center align-items-center">
            <img class="me-2" width="24" src="@/assets/icon/checked.png" alt="咖啡豆推薦浪勁品質把關" />
            <h3 class="my-auto fw-bold">品質把關</h3>
          </div>
        </div>
        <div class="col-md">
          <div class="d-flex justify-content-center align-items-center">
            <img class="me-2" width="24" src="@/assets/icon/checked.png" alt="咖啡豆推薦浪勁濃郁香氣" />
            <h3 class="my-auto fw-bold">濃郁香氣</h3>
          </div>
        </div>
        <div class="col-md">
          <div class="d-flex justify-content-center align-items-center">
            <img class="me-2" width="24" src="@/assets/icon/checked.png" alt="咖啡豆推薦浪勁奔放風味" />
            <h3 class="my-auto fw-bold">奔放風味</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="coffee-type py-3 text-white text-center">
    <div class="container">
      <div class="d-flex justify-content-around">
        <a v-on:click="spyScroll(b.spy, b.collapse_id)" v-for="b in beans" :key="b.type" class="d-flex flex-column align-items-center bean-cat text-decoration-none">
          <span class="badge rounded-pill mb-2 bean-pill fw-light">#{{ b.type }}</span>
          <img :src="require(`@/assets/icon/${b.bean_type}.png`)" width="42" :alt="`新鮮咖啡豆${b.name}`" />
          <h5 class="fw-bold text-base text-gray">{{ b.name }}</h5>
        </a>
      </div>
    </div>
  </section>
  <section>
    <div class="accordion text-center">
      <div v-for="b in beans" :key="b.type" class="accordion-item" :class="b.bg_class" :id="b.spy">
        <h2 class="accordion-header" :id="b.accordion_id">
          <button class="accordion-button bg-cover" :class="b.cat" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + b.collapse_id" aria-expanded="true" :aria-controls="b.collapse_id"></button>
        </h2>
        <div :id="b.collapse_id" class="accordion-collapse collapse show" :aria-labelledby="b.accordion_id">
          <div class="accordion-body my-4">
            <div class="container">
              <div class="row row-cols-1 row-cols-lg-2 g-4">
                <div class="col" v-for="p in productList[b.type]" :key="p.id">
                  <Product :beanObj="p" @click="pictureFullModal(p)"></Product>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container mt-4">
    <div class="text-center">
      <h3><img src="~@/assets/icon/cart.png" alt="" width="65" /></h3>
      <h3 class="mb-4 fw-bolder">-購買方法-</h3>
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row">
            <div class="col-md mb-2">
              <div class="d-flex flex-column">
                <h4 class="fw-bolder">下單購買</h4>
                <h3 style="height: 90px"><img src="~@/assets/icon/buy-1.png" alt="" width="60" /></h3>
                <h5 class="fw-bolder">下單購買</h5>
              </div>
            </div>
            <div class="col-md mb-2">
              <div class="d-flex flex-column">
                <h4 class="fw-bolder">付款轉帳</h4>
                <h3 style="height: 90px">
                  <img src="~@/assets/icon/buy-2.png" alt="" width="70" />
                </h3>
                <h5 class="fw-bolder">ATM 轉帳</h5>
              </div>
            </div>
            <div class="col-md mb-2">
              <div class="d-flex flex-column">
                <h4 class="fw-bolder">安排烘⾖</h4>
                <h3 style="height: 90px">
                  <img src="~@/assets/icon/buy-3.png" alt="" width="75" />
                </h3>
              </div>
            </div>
            <div class="col-md mb-2">
              <div class="d-flex flex-column">
                <h4 class="fw-bolder mb-3">商品出貨</h4>
                <h3 style="height: 90px">
                  <img src="~@/assets/icon/buy-4.png" alt="" width="110" />
                </h3>
              </div>
            </div>
            <div class="col-md mb-2">
              <div class="d-flex flex-column">
                <h4 class="fw-bolder">超商取貨</h4>
                <h3 style="height: 90px">
                  <img src="~@/assets/icon/buy-5.png" alt="" width="70" />
                </h3>
                <h5 class="fw-bolder">各大超商都可選</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container my-4">
    <div class="text-center">
      <h3><img src="~@/assets/icon/new.png" alt="" width="65" /></h3>
      <h3 class="mb-4 fw-bolder">-精彩活動-</h3>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md mb-2">
              <div class="d-flex flex-column">
                <h4 class="fw-bolder"></h4>
                <img src="https://www.linkpicture.com/q/LPic63fc921224987980646134.jpg" type="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="modal fade" id="p-full-screen" data-bs-scroll="true" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm-xl modal-fullscreen modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title fw-bold">{{ currentProduct.p_ch_name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" :style="{ backgroundImage: 'url(' + require(`@/assets/product/${currentProduct.p_src}`) + ')' }" style="background-size: contain; background-repeat: no-repeat; background-position: center">
          <!-- <img :src="require(`@/assets/product/${currentProduct.p_src}`)" class="rounded w-75 d-block mx-auto" :alt="currentProduct.p_ch_name" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.text-gray {
  color: rgb(46, 40, 40);
}
</style>
