<template>
  <div class="card bg-transparent">
    <div class="position-relative p-view" data-bs-toggle="modal" data-bs-target="#p-full-screen">
      <img :src="require(`@/assets/product/${beanObj.p_src}`)" class="rounded w-100" :alt="beanObj.p_ch_name" />
      <div class="full-screen-icon">
        <img src="~@/assets/eye.png" :alt="`1111特價浪勁咖啡豆 ${beanObj.p_ch_name}`" />
      </div>
    </div>
    <div class="card-body">
      <h5 class="card-title fw-bolder">{{ beanObj.p_ch_name }}</h5>
      <h5 class="card-title fw-bolder">$ {{ beanObj.p_price }} / {{ beanObj.p_spec }}</h5>
      <div class="d-grid gap-2">
        <button class="btn btn-dark btn-block add-to-cart" type="submit" @click="storeCart.increaseCount(beanObj)">加入購物車</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, onMounted, ref } from "vue";
import { useCartStore } from "@/store";
const storeCart = useCartStore();

const props = defineProps({
  beanObj: Object,
});
const modalData = ref(null);
onMounted(() => {
  modalData.value = props.beanObj;
});
</script>
<style>
.full-screen-icon {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 45%;
  background: white;
  border-radius: 50px;
  padding: 5px;
}

.p-view:hover .full-screen-icon {
  visibility: visible;
}
.p-view {
  cursor: pointer;
}
.p-view img {
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
  -webkit-transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
  width: 40px;
}
.p-view:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.vertical-divider {
  height: 40vh;
  border-left: 2px solid #dbdbdb;
  align-self: center;
}
.custom-icon {
  color: #fff;
  background: rgb(75, 73, 73);
  padding: 11px;
  border-radius: 30px;
}
.custom-icon:hover {
  background: rgb(75, 73, 73, 0.8);
}
</style>
